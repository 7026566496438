import { useState, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Dashboard from "./scenes/dashboard";
import Sidebar from "./scenes/global/Sidebar";
import Login from "./scenes/Login/login";
import { Provider } from "react-redux";
import store from "./Redux/store";
import ViewPjp from "./scenes/Pjp/ViewPjp";
import Architect from "./scenes/Architect/Architect";
import Builder from "./scenes/Builder/Builder";
import Contractor from "./scenes/Contractor/Contractor";
import Competitor from "./scenes/Competitor/Competitor";
import Others from "./scenes/Others/Others";
import Dealer from "./scenes/Dealer/Dealer";
import Subdealer from "./scenes/SubDealer/Subdealer";
import Visits from "./scenes/Visits/Visits";
import Payments from "./scenes/Payments/Payments";
import ForgetPassword from "./scenes/Login/ForgotPassword";
import ResetPassword from "./scenes/Login/ResetPassword";
import Profile from "./scenes/Profile/Profile";
import ChangePassword from "./scenes/ChangePassword/ChangePassword";
import VisitsOnMap from "./scenes/Visits/VisitsOnMap";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar] = useState(true);
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const isForgetPasswordPage = location.pathname === "/forgot-password";
  const isResetPasswordPage = location.pathname.startsWith("/password-reset");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (
      !token &&
      !isLoginPage &&
      !isForgetPasswordPage &&
      !isResetPasswordPage
    ) {
      // Redirect to login if token is not available
      window.location.href = "/login";
    }
  }, [isLoginPage, isForgetPasswordPage, isResetPasswordPage]);

  return (
    <Provider store={store}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app" style={{ display: "flex", height: "100%" }}>
            {!isLoginPage && !isForgetPasswordPage && !isResetPasswordPage && (
              <Sidebar />
            )}
            <div
              className="content"
              style={{
                flex: 1,
                overflowY: "auto",
                transition: "margin 0.2s ease", // Optional: add a transition effect
              }}
            >
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgetPassword />} />
                <Route
                  path="/password-reset/:token"
                  element={<ResetPassword />}
                />
                <Route path="/" element={<Dashboard />} />
                <Route path="/viewpjp" element={<ViewPjp />} />
                <Route path="/dealer" element={<Dealer />} />
                <Route path="/subdealer" element={<Subdealer />} />
                <Route path="/architect" element={<Architect />} />
                <Route path="/builder" element={<Builder />} />
                <Route path="/contractor" element={<Contractor />} />
                <Route path="/competitor" element={<Competitor />} />
                <Route path="/others" element={<Others />} />
                <Route path="/visits" element={<Visits />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/payments" element={<Payments />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/visits-on-map" element={<VisitsOnMap />} />
              </Routes>
            </div>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Provider>
  );
}

export default App;
