import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
} from "@mui/material";
import Topbar from "../global/Topbar";
import { useDispatch, useSelector } from "react-redux";
import { fetchViewAllDealer } from "../../Redux/Slice/AllDealer/ViewAllDealerSlice";
import { fetchViewPjp } from "../../Redux/Slice/Pjp/ViewPjpSlice";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { fetchViewDashboard } from "../../Redux/Slice/Dashboard/ViewDashboardSlice";
import { Link } from "react-router-dom";
import { fetchViewDealer } from "../../Redux/Slice/Dealer/ViewDealerSlice";
import { fetchViewSubdealer } from "../../Redux/Slice/Subdealer/ViewSubdealerSlice";
import { fetchViewClient } from "../../Redux/Slice/Client/ViewClientSlice";

const Dashboard = () => {
  const dispatch = useDispatch();

  const DashboardData = useSelector(
    (state) => state.ViewDashboard.ViewDashboardData
  );
  console.log("dash", Dashboard);
  const AllPjp = useSelector((state) => state.ViewPjp.ViewPjpData?.data);
  const countAllPjp = () => {
    if (!AllPjp || !Array.isArray(AllPjp)) {
      return 0;
    }
    return AllPjp.length;
  };
  //
  const DealerData = useSelector(
    (state) => state.ViewDealer.ViewDealerData?.data
  );
  const countDealer = () => {
    if (!DealerData || !Array.isArray(DealerData)) {
      return 0;
    }
    return DealerData.length;
  };
  //
  const SubdealerData = useSelector(
    (state) => state.ViewSubdealer.ViewSubdealerData?.data
  );
  const countSubdealer = () => {
    if (!SubdealerData || !Array.isArray(SubdealerData)) {
      return 0;
    }
    return SubdealerData.length;
  };
  const ClientData = useSelector(
    (state) => state.ViewClient.ViewClientData?.data
  );
  const countDealersByRole = (categoeryId) => {
    if (!ClientData || !ClientData || !Array.isArray(ClientData)) {
      return 0;
    }
    return ClientData.filter((Client) => Client.categoery_id === categoeryId)
      .length;
  };

  //
  React.useEffect(() => {
    dispatch(fetchViewDealer());
    dispatch(fetchViewPjp());
    dispatch(fetchViewDashboard());
    dispatch(fetchViewSubdealer());
    dispatch(fetchViewClient());
  }, [dispatch]);
  const prepareGraphData = () => {
    if (!DashboardData || typeof DashboardData !== "object") {
      return [];
    }

    const graphData = Object.keys(DashboardData).map((month) => ({
      month,
      value: DashboardData[month],
    }));

    return graphData;
  };
  const calculateTotalVisits = () => {
    if (!DashboardData || typeof DashboardData !== "object") {
      return 0;
    }

    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString("default", {
      month: "long",
    });

    return DashboardData[currentMonth] || 0;
  };

  const totalVisits = calculateTotalVisits();

  const graphData = prepareGraphData();
  return (
    <div style={{ background: "linear-gradient(to right, #ffedff, #fff)" }}>
      <Topbar />
      <Grid container spacing={3} style={{ padding: "30px" }}>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#f8ee1f" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Typography variant="h5" component="div">
                  Pjp
                </Typography>
                <Typography variant="h2" component="div">
                  {countAllPjp()}
                </Typography>
              </div>
            </CardContent>
            <CardActions>
              <Link to="/viewpjp">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#e739fc" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Typography variant="h5" component="div">
                  Dealer
                </Typography>
                <Typography variant="h2" component="div">
                  {countDealer()}
                </Typography>
              </div>
            </CardContent>
            <CardActions>
              <Link to="/dealer">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#95fba0" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Typography variant="h5" component="div">
                  Subdealer
                </Typography>
                <Typography variant="h2" component="div">
                  {countSubdealer()}
                </Typography>
              </div>
            </CardContent>
            <CardActions>
              <Link to="/subdealer">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#f9bcff" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Typography variant="h5" component="div">
                  Architect
                </Typography>
                <Typography variant="h2" component="div">
                  {countDealersByRole("5")}
                </Typography>
              </div>
            </CardContent>
            <CardActions>
              <Link to="/architect">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#9fa3f2" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Typography variant="h5" component="div">
                  Builder
                </Typography>
                <Typography variant="h2" component="div">
                  {countDealersByRole("6")}
                </Typography>
              </div>
            </CardContent>
            <CardActions>
              <Link to="/builder">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#bf62ff" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Typography variant="h5" component="div">
                  Contractor
                </Typography>
                <Typography variant="h2" component="div">
                  {countDealersByRole("7")}
                </Typography>
              </div>
            </CardContent>
            <CardActions>
              <Link to="/contractor">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#f9ff59" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Typography variant="h5" component="div">
                  Competitor
                </Typography>
                <Typography variant="h2" component="div">
                  {countDealersByRole("8")}
                </Typography>
              </div>
            </CardContent>
            <CardActions>
              <Link to="/competitor">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#fe8553" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Typography variant="h5" component="div">
                  Other
                </Typography>
                <Typography variant="h2" component="div">
                  {countDealersByRole("10")}
                </Typography>
              </div>
            </CardContent>
            <CardActions>
              <Link to="/others">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            style={{
              backgroundColor: "#90ff86",
              color: "#fff",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "30px",
                }}
              >
                <Typography
                  variant="h2"
                  component="div"
                  style={{ color: "#000" }}
                >
                  Total Visits
                </Typography>
                <Typography
                  variant="h1"
                  component="div"
                  style={{ color: "#000" }}
                >
                  {totalVisits}
                </Typography>
              </div>
              <ResponsiveContainer height={400}>
                <BarChart data={graphData}>
                  <XAxis dataKey="month" tick={{ fill: "#000" }} />
                  <YAxis tick={{ fill: "#000" }} />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "#fff",
                      color: "#d169dd",
                    }}
                  />
                  <Bar dataKey="value" fill="#d169dd" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
            <CardActions>
              <Link to="/visits">
                <Button size="small" style={{ color: "#000" }}>
                  Learn More
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
